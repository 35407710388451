import { useToSvg } from "@hugocxl/react-to-image";
import { useEffect, useState } from "react"
import { svgToPng } from "./svgToPng";

export interface IpadContainerProps {
    children: JSX.Element,
    height: number,
    setWidth?: (w: number) => any
}

export const IpadContainer = (props: IpadContainerProps) => {
    const [height, setHeight] = useState(props.height);
    const [width, setWidth] = useState(0);
    const [outHeight, setOutHeight] = useState(0);
    const [outWidth, setOutWidth] = useState(0);
    useEffect(() => {
        setWidth(height * 3 / 4);
        setOutHeight(height + 2 * 15);
        setOutWidth(height * 3 / 4 + 2 * 15);
        props.setWidth && props.setWidth(height * 3 / 4 + 2 * 15);
    }, [height])
    const [state, toSvg, ref] = useToSvg<HTMLDivElement>({
        onSuccess: async data => {
            const link = document.createElement('a');
            const pngUri = await svgToPng(data, outWidth*2, outHeight*2);
            link.href = pngUri;
            link.download = `${new Date().getTime()}.png`;
            link.click();
        }
    });
    return (
        <div style={{
            display: "flex",
            flexDirection: "column"
        }}
            ref={ref}
            onClick={() => {
                console.log("hook");
                toSvg();
            }}
        >
            <div style={{
                height: 3,
                display: "flex",
                flexDirection: "row",
                width: height * 3/4 + 2 * 15
            }}>
                <div style={{ flex: 18 }} />
                <div style={{ 
                    flex: 1, 
                    backgroundColor: "gray", 
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    border: "none"
                }} />
                <div style={{ flex: 1 }} />
            </div>
            <div style={{
                display: "flex"
            }}>
                <div style={{
                    width: outWidth,
                    height: outHeight,
                    border: "solid",
                    borderWidth: 1,
                    borderRadius: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#000"
                }}>
                    <div style={{
                        width: width,
                        height: height,
                        border: "solid",
                        borderWidth: 1,
                        borderRadius: 5,
                        backgroundColor: "#fff",
                        overflow: "hidden"
                    }}>
                        {props.children}
                    </div>
                </div>
                <div style={{
                    width: 3,
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <div style={{
                        flex: 1.5
                    }}/>
                    <div style={{
                        flex: 2,
                        flexDirection: 'column',
                        display: 'flex'
                    }}>
                        <div style={{
                            flex: 1,
                            backgroundColor: 'gray',
                            border: "none",
                            borderTopRightRadius: 3,
                            borderBottomRightRadius: 3,
                        }}/>
                        <div style={{
                            flex: 0.2
                        }} />
                        <div style={{
                            flex: 1,
                            backgroundColor: 'gray',
                            border: "none",
                            borderTopRightRadius: 3,
                            borderBottomRightRadius: 3,
                        }}/>
                    </div>
                    <div style={{
                        flex: 18
                    }}>

                    </div>
                </div>
            </div>
        </div>
    )
}