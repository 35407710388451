import { useToPng, useToSvg } from "@hugocxl/react-to-image"
import { IpadContainer } from "./IpadContainer";
import { IphoneContainer } from "./IphoneContainer";
import { useState } from "react";
import { PrimaryColor } from "../Themes";
import { svgToPng } from "./svgToPng";

export interface ExportAppPreviewProps {
    ratio: number,
    device: "iphone" | "ipad",
    background: string,
    title: string,
    desc: string[],
    children: JSX.Element,
    exportHeight: number,
    exportWidth: number
}


export const ExportAppPreview = (props: ExportAppPreviewProps) => {
    const [width, setWidth] = useState(0);
    const [state, toSvg, ref] = useToSvg<HTMLDivElement>({
        onSuccess: async data => {
            const link = document.createElement('a');
            const pngUri = await svgToPng(data, props.exportWidth, props.exportHeight);
            link.href = pngUri;
            link.download = `${props.title}.png`;
            link.click();
        }
    })
    return (
        <div style={{
            display: "flex",
            flexDirection: "column"
        }}>
            <div
                ref={ref}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: 600 * 5 / 4,
                    width: 600 * 5 / 4 / props.ratio,
                    alignItems: "center",
                    justifyContent: "center",
                    background: props.background,
                }}
            >
                <div style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "center"
                }}>

                <text style={{
                    fontSize: 20,
                    fontWeight: "bold"
                }}>
                    {props.title}
                </text>
                {
                    props.desc.map((v, i) => {
                        return (
                            <text style={{
                                fontSize: 15,
                            }}
                                key={i}
                            >
                                {v}
                            </text>
                        )
                    })
                }

                </div>
                {
                    props.device === "ipad" ?
                        <IpadContainer
                            height={600}
                            setWidth={setWidth}
                        >
                            {props.children}
                        </IpadContainer>
                        :
                        <IphoneContainer
                            height={600}
                        >
                            {props.children}
                        </IphoneContainer>
                }
            </div>
            <div style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                display: "flex",
                marginTop: 20,
            }}>

                <div style={{
                    backgroundColor: PrimaryColor.color2,
                    border: "none",
                    width: 'auto',
                    borderRadius: 10,
                    padding: 10
                }}
                    onClick={() => {
                        toSvg()
                    }}
                >
                    <a
                        href="javascript:void(0)"
                        style={{
                            textDecoration: "none"
                        }}>
                        下载
                    </a>
                </div>
            </div>
        </div>
    )
}
