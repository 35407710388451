import { ScreenView } from "./pages/ScreenView";

function App() {
    return (
        <div>
            <ScreenView/>
        </div>
    );
}

export default App;
