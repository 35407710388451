import { useState } from "react"
import { PrimaryColor } from "./Themes"

export const AboutView = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <div style={{
                display: 'flex',
                height: "80vh",
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: "column"
            }}>
                <text>
                    {"Anki笔记森林是"}
                    <a href="https://github.com/tsogzark">
                        @tsogzark
                    </a>
                    {"独立开发的一款APP"}
                </text>
                <text>
                    您的支持是我坚持下去的动力
                </text>
                <text>
                    邮箱: 1436238243@qq.com
                </text>
            </div>
        </div>
    )
}