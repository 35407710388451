export const BlueGreens = {
    color0: "#e1f0e9",
    color1: "#c5e5d6",
    color2: "#abdac6",
    color3: "#8ed0b5",
    color4: "#71c6a5",
}

export const Greens = {
    color0: "#e0efd8",
    color1: "#c5e2b6",
    color2: "#abd595",
    color3: "#8ec975",
    color4: "#70c055",
}

export const Blues = {
    color0: "#dbedfb",
    color1: "#b8def5",
    color2: "#96d1f3",
    color3: "#70c3ed",
    color4: "#40b8ea",
}

export const Pinks = {
    color0: "#fbd6de",
    color1: "#f7aec1",
    color2: "#f389a7",
    color3: "#f15f90",
    color4: "#ec197a",
}

export const BlueViolets = {
    color0: "#d2d7ee",
    color1: "#abb7dd",
    color2: "#889bcf",
    color3: "#6783c2",
    color4: "#436fb6",
}


export const PrimaryColor = BlueGreens;
export const SecondaryColor = Blues;
export const TertiaryColor = BlueViolets;
export const AntiColor = Pinks;