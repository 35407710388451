import Markdown from "react-markdown";

export const PrivacyView = () => {
    return (
        <div style={{
            padding: 50
        }}>

        <Markdown>
            {`
# Anki笔记森林隐私条款

本版发布日期: 2024年4月1日  
本版生效日期: 2024年4月1日  
    
本政策仅适用于北京欧拉星空智能科技有限公司的"Anki笔记森林"产品
本软件尊重并保护所有使用服务用户的个人隐私权。本软件会按照本隐私权政策的规定使用和披露您的个人信息。但本软件将以高度的勤勉、审慎义务对待这些信息。本软件会不时更新本隐私权政策。您在同意本软件服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本软件服务使用协议不可分割的一部分。
您可在本软件个人中心-查看用户协议里撤销同意隐私政策。
    
## 1.适用范围  
a.在您使用本软件网络服务，本软件自动接收并记录的您的手机上的信息，包括但不限于您使用的语言、访问日期和时间、软硬件特征信息(Mac地址、唯一设备识别码)、设备序列号，文字记录，图片及文档, 已安装的应用列表, AAID数据；  
b.所有权限都可撤销, 可在本软件设置->权限管理->权限里关闭不再使用的权限。
    
## 2.如何收集和使用您的个人信息
您理解并同意：  
为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。如您是未成年人的法定监护人，在您同意未成年人个人信息收集使用规则之后，我们方可收集和使用未成年人的个人信息；如您是未成年人用户，建议在您的法定监护人知晓并同意信息收集使用规则之后，授权我们收集和使用您的个人信息。在此过程中，如果您有任何疑问、意见或建议的，您可通过与我们联系，我们会尽快为您作出解答。    
### （1）成为我们的注册用户并且登录    
如果您成为我们的注册用户，您需要提供注册手机号(或邮箱)和密码， 并以此信息作为关联账号用以登录；    
### （2）APP隐私权限及相关说明  
1.获取写入外置存储器（媒体和文件）权限，此权限在保存、加载笔记，读取并将本地图片保存在笔记中时使用；  
2.获取相册和摄像头权限，此权限用于您拍照导入笔记时使用；  
3.访问互联网权限，此权限用于登录账号，接入第三云盘(百度网盘，华为云盘等)备份笔记，还有内置浏览器浏览图片功能;  
4.获取已安装应用列表权限, 用于检测支付宝是否已安装在本机，以判断是否支持通过本设备上的支付宝进行支付;  
5.应用内安装其他应用权限，用于自更新，安装本软件更新包;  
6.获取网络状态,用于检测当前的网络连接是否有效;  
7.读取您的外置存储器（媒体和文件）权限，此权限用于读取您存储在外置存储器上的笔记数据;

### （3）第三方SDK清单

1.ReactNative,AndroidSupportLibraryFragment,AndroidX Fragment
- 包名
> com.facebook.react, androidx.core, android.support, org.wonday.orientation
- 使用目的
> 获取传感器列表，加速度重力传感器信息, 用于设置APP使用时的屏幕方向，以让用户获得最佳体验
- 使用频率
> 在用户横置或竖置手机时调用

2.支付宝SDK
- 包名
> com.alipay
- 使用目的
> 接入支付宝支付功能，使用户可以通过支付宝购买应用内付费项目
- 使用频率
> 用户购买时调用

3.谷歌广告SDK
- 包名
> com.google.ads
- 使用目的
> 用于在非中国大陆的国家与区域植入应用内广告
- 使用频率
> 在用户播放视频时接入谷歌广告, 只在非中国大陆的国家与区域调用

4.ReactNative Blob处理SDK
- 包名
> com.ReactNativeBlobUtil
- 使用目的
> 用于处理二进制文件的网络请求以及二进制的本地读取与保存
- 使用频率
> 在用户进行数据的同步以及本地保存时调用

5.okhttp3网络请求库
- 包名
> com.squareup.okhttp3
- 使用目的
> 用于处理与应用后端通讯的http请求
- 使用频率
> 在用户登录注册，数据同步，支付时调用
    
请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。  
为展示您账户的订单信息，我们会收集您在使用我们服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。  
当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。  
我们通过间接获得方式收集您的个人信息  
为确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构等收集与交易进度相关的您的交易、支付信息，或将您的交易信息共享给上述服务提供者。  
### （3）为您提供安全保障  
为提高您使用我们及我们合作伙伴提供服务的安全性，确保操作环境安全与识别注册账号异常状态，更好地保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或中博教育网相关协议规则的情况，我们可能使用或整合您的用户信息、交易信息、设备信息、有关网络日志以及我们合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。  
    
## 3.信息披露  
a)本软件不会将您的信息披露给经您同意受信任的第三方。  
b)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；  
c)如您出现违反中国有关法律、法规或者相关规则的情况，需要向第三方披露；  
    
    
## 4.信息存储和交换  
本软件收集的有关您的信息和资料将保存在本软件及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本软件收集信息和资料所在地的境外并在境外被访问、存储和展示。  
    
## 5.信息安全  
在使用本软件网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，请您立即联络本软件客服，以便本软件采取相应措施。  
    
## 6.个人信息保存期限  
除法律法规或监管部门另有规定外，我们仅在本政策所述目的所必需且最短的时限内存储您的个人信息。  
如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。  
如果你申请注销帐户、主动删除个人信息或超出必要的期限后，我们将对你的个人信息进行删除或匿名化处理，但法律法规规定有保留期限的除外。  
我们在中华人民共和国境内收集的个人信息，存储在中国境内，如需跨境传输的，我们会单独请求获得您的授权同意，并确保依据国家法律法规和相关监管部门的规定执行，以对您的个人信息提供足够的保护。  
    
## 7.账号注销指引  
亲爱的用户，在您正式开始下一步账号注销流程前，我们先为您做出如下特别说明：注销账号后，您将无法再以此账号登录和使用本软件产品与服务，这同时也可能会给您的售后维权带来不便。且账号一旦注销完成，账号信息将会删除，且无法恢复。请您在注销前慎重考虑。  
如何注销:  
您需登录后，打开个人信息界面，点击"注销账号"进行注销操作，我们将会确认信息后对您的账号信息删除；  
如在账号注销过程中有任何问题您无法处理的或在处理过程中有任何疑惑的，可联系客服协助处理。  
    
## 8.本政策如何更新  
本隐私政策的内容可能会不时更新，以适应法律、技术或商业的发展。更新后的隐私政策将用户通知等合适的形式告知用户。当您使用我们的软件时，您可以随时审查我们的隐私政策，以便您了解其修改。您可以在本隐私通知的顶部的“更新日期”的日期处查看本隐私的最新更新日期。  
若您不同意修改后的隐私政策，您有权并应立即停止使用我们的产品和/或服务。如果对本政策的更新存在疑义，请您及时通过本政策公示的联系方式与我方联系。  
    
## 9.用户反馈  
如您在使用过程遇到问题，或者个人信息安全有疑问的，可在本软件关于笔记->意见反馈里反馈问题，或者发邮件到客服邮箱1436238243@qq.com。会在10个工作日内回复。
   `}
        </Markdown>
        </div>
    )
}