import { useState } from "react"
import { PrimaryColor } from "./Themes"
import { Link } from "react-router-dom"

export const ContractView = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <div style={{
                display: 'flex',
                height: "80vh",
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Link to={"/privacy-policy"} style={{textDecoration: "none"}}>
                    <div style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 10,
                        border: "none",
                        borderRadius: 10,
                        backgroundColor: PrimaryColor.color2,
                        margin: 20
                    }}>
                        <text>
                            {"隐私协议"}
                        </text>
                    </div>
                </Link>
                <Link to={"/terms-of-use"} style={{textDecoration: "none"}}>
                    <div style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 10,
                        border: "none",
                        borderRadius: 10,
                        backgroundColor: PrimaryColor.color2,
                        margin: 20
                    }}>
                        <text>
                            {"用户协议"}
                        </text>
                    </div>
                </Link>
            </div>
        </div>
    )
}