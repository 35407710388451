import { useToSvg } from "@hugocxl/react-to-image";
import { Fragment, useEffect, useState } from "react"
import { svgToPng } from "./svgToPng";

export interface IphoneContainerProps {
    children: JSX.Element,
    height: number
}

export const IphoneContainer = (props: IphoneContainerProps) => {
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [outHeight, setOutHeight] = useState(0);
    const [outWidth, setOutWidth] = useState(0);
    useEffect(() => setHeight(props.height), []);
    useEffect(() => {
        setWidth(height * 6 / 13);
        setOutWidth(height * 6 / 13 + 12);
        setOutHeight(height + 12);
    }, [height]);
    const [state, toSvg, ref] = useToSvg<HTMLDivElement>({
        onSuccess: async data => {
            const link = document.createElement('a');
            const pngUri = await svgToPng(data, outWidth*2, outHeight*2);
            link.href = pngUri;
            link.download = `${new Date().getTime()}.png`;
            link.click();
        }
    });
    if (width === 0) return <Fragment/>
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            height: outHeight,
            width: "auto",
        }}
            ref={ref}
            onClick={toSvg}
        >
            <div style={{
                width: 3,
                display: "flex",
                flexDirection: "column"
            }}>
                <div style={{ flex: 1.5 }} />
                <div style={{ 
                    flex: 2.5, 
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <div style={{
                        flex: 1, 
                        backgroundColor: "gray",
                        border: "none",
                        borderTopLeftRadius: 3,
                        borderBottomLeftRadius: 3
                    }}/>
                    <div style={{flex: 0.3}}/>
                    <div style={{
                        flex: 2, 
                        backgroundColor: "gray",
                        border: "none",
                        borderTopLeftRadius: 3,
                        borderBottomLeftRadius: 3
                    }}/>
                    <div style={{flex: 0.3}}/>
                    <div style={{
                        flex: 2, 
                        backgroundColor: "gray",
                        border: "none",
                        borderTopLeftRadius: 3,
                        borderBottomLeftRadius: 3
                    }}/>
                </div>
                <div style={{ flex: 6 }} />
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                borderRadius: 26,
                backgroundColor: "#000",
                height: outHeight,
                width: outWidth
            }}>
                <div style={{
                    height: height,
                    width: width,
                    borderWidth: 1,
                    border: "none",
                    borderRadius: 20,
                    backgroundColor: "#fff",
                    overflow: "hidden",
                    position: "relative"
                }}>
                    {props.children}
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            height: 28,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0
                        }}>
                            <img
                                src={require("../../assets/statusbar.png")}
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                            />
                        </div>
                        <div  style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex'
                        }}>

                        <div
                            style={{
                                border: "none",
                                borderRadius: 10,
                                width: "25%",
                                height: 20,
                                backgroundColor: "#000"
                            }}
                        />

                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                width: 3,
                display: "flex",
                flexDirection: "column"
            }}>
                <div style={{ flex: 3 }} />
                <div style={{ 
                    flex: 2, 
                    backgroundColor: "gray",
                    border: "none",
                    borderTopRightRadius: 3,
                    borderBottomRightRadius: 3,
                }}/>
               <div style={{ flex: 9 }} />
 
            </div>

        </div>
    )
}