export const svgToPng = (svgDataurl, width, height) => new Promise((resolve, reject) => {
    let canvas;
    let ctx;
    let img;

    img = new Image();
    img.src= svgDataurl;
    img.onload = () => {
        canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext('2d');
        console.log("src width", img.width);
        console.log("src height", img.height);
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);

        img = new Image();
        img.src = canvas.toDataURL('image/png');
        img.onload = () => {
            canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            resolve(canvas.toDataURL('image/png'));
        }
    };
});