import Markdown from "react-markdown";

export const UsageView = () => {
    return (
        <div style={{
            padding: 50
        }}>
            <Markdown>
                {`
# Anki笔记森林用户协议  
本版发布日期：2024年4月1日  
本版生效日期：2023年4月1日  
### 一、接受条款  
Anki笔记森林的所有权和运营权归北京欧拉星空智能科技有限公司（下称“我司”）所有。我司根据《Anki笔记森林用户协议》（下称“本协议”）以及不时发布的所有与Anki笔记森林用户服务（下称“本服务”）相关的其他条款和规则，为您提供基于Anki笔记森林的服务。您一旦使用了Anki笔记森林客户端程序，或者您在Anki笔记森林客户端程序、微信公众号、小程序、网站等任何Anki笔记森林平台上注册了相关账户，即代表您已经成为Anki笔记森林的用户（下称“用户”、“您”），代表您同意接受本协议的约束。请您仔细阅读本协议条款（特别是加粗部分）。如您是未成年人应在您监护人的陪同下阅读并同意本协议。  
本协议可由我司根据实际情况进行修改，修改后的协议在Anki笔记森林客户端程序或网页上一经公布即生效并代替原有的协议。如您在我司修改和更新本协议后继续使用Anki笔记森林，即视为您接受修改后的协议。  
您应在遵守法律和本协议各项条款前提下使用Anki笔记森林，否则我司有权中断或终止为您提供本服务。  
   
### 二、服务内容  
1 本服务的内容由我司根据实际情况提供，我司有权对Anki笔记森林的产品形态或服务内容进行升级或调整，并及时更新相关介绍。  
2 基础服务：包括但不限于用户注册与登录信息管理、创建笔记及基础操作功能、导入及导出来自不同设备的笔记、上传或分享笔记至第三方网络平台等。  
3 vip服务：您通过付费方式成为Anki笔记森林vip会员，或者是您获得限时免费试用Anki笔记森林vip会员功能，从而解锁了Anki笔记森林vip会员功能（vip会员功能明细以Anki笔记森林客户端程序中公布的vip会员功能列表为准）的使用权。  
4 本服务包括但不限于我司通过Anki笔记森林的电脑端或移动客户端程序(APP)、Anki笔记森林相关网站、微信公众号、微信小程序等平台提供的所有服务。  
   
### 三、我司通过国际互联网络、客户端程序(APP)为您提供本服务  
您必须：  
1 认真阅读并遵守本协议及其他规则。  
2 自行准备设备，包括与本服务有关的终端设备（如电脑或移动终端）和必要的网络接入设备等。  
3 承担个人上网和与本服务有关的费用。  
4 遵守中华人民共和国相关法律法规（如果您是中华人民共和国境外的使用者，还应同时遵守其所在国家或地区的法律法规）。  
若我司有需要，您有义务提供Anki笔记森林软件在用户部署时的各种安装及运行环境，包括但不限于IP地址、操作系统和网络环境等。  
   
### 四、服务质量监测  
1 为了监测和改善本服务的质量，在法律法规允许的范围内，我司在适当的时候会把Anki笔记森林软件运行时与本服务质量有关的基本数据（包括但不仅限于确认您的注册与登录信息、查询软件运行中的问题原因、我司用于改善本服务必要的用户终端设备参数等）发送到我司服务器。  
2 我司会按照本协议及其他发布的规则提供本服务，但是不承诺解决非因我司引起的问题，包括且不限于您电脑或移动终端软硬件故障、您使用不兼容的浏览器、您使用未经我司适配过的电脑或移动终端软硬件、您所在网络出现故障、您的网络接入停止解析本服务相关域名或者中断和本服务所在服务器的连接等。  
   
### 五、用户的权利和义务  
1 注册与登录  
为了方便您体验本服务，经您确认并同意了本协议后即可登录本服务。但是为了您更好、更安全使用本服务，我司建议您及时注册一个含有您个人正确信息的Anki笔记森林账号后登录本服务，或者使用Anki笔记森林认可的其他第三方平台帐号注册及登录本服务。  
您了解并同意，您在注册或授权登录时应提交详尽且准确的注册资料（且应及时更新以确保相关资料信息真实有效）。若您提供的注册资料不准确、不真实或含有违法或不良信息的，我司有权不予注册，并保留随时终止您使用我司各项服务的权利。  
您确认，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，确保有能力对您使用本服务的一切行为独立承担责任。  
2 使用规则  
2.1内容与服务使用规则  
请勿侵犯他人的肖像权、名誉权、隐私权、知识产权、个人信息、商业秘密等合法权益。严禁从事侵犯他人权利或违反适用法律的商业活动或其他活动。  
请勿尝试复制或转售Anki笔记森林相关服务。请勿尝试复制、开发或违规使用Anki笔记森林的任何部分功能及服务。请勿在未经预先授权的情况下通过任何形式转让Anki笔记森林的任何服务给任何第三方。  
请勿超越经授权的使用范围。切勿访问或使用您无权使用的服务。未经预先授权，请勿收集、索取或存储有关他人的个人材料，亦不得修改和破坏其他用户的内容。  
请勿侵犯知识产权。严禁上传、发布或传播任何侵犯任何方的专利、商标、商业秘密、版权或其他知识产权的内容。  
请勿上传或发布含有下列内容之一的信息：  
（1）违反宪法或法律法规规定的；  
（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；  
（3）损害国家荣誉和利益的；  
（4）煽动民族仇恨、民族歧视、破坏民族团结的；  
（5）破坏国家宗教政策，宣扬邪教和封建迷信的；  
（6）散布谣言，扰乱社会秩序，破坏社会稳定的；  
（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；  
（8）侮辱或者诽谤他人，侵害他人合法权利的；  
（9）以非法民间组织名义活动的；  
（10）不符合“七条底线”（遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等）要求的；  
（11）含有法律、行政法规禁止的其他内容的。  
2.2行为规范  
您应充分理解并同意，在您开始使用本服务后，您必须为自己使用Anki笔记森林过程的一切行为负责。同时您应对您使用的任何内容自行加以判断，并承担因使用任何内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。我司无法且不会对因前述风险而导致的任何损失或损害承担责任。  
以下行为是禁止的：  
（1）多人使用同一个Anki笔记森林账号；  
（2）未经授权转售Anki笔记森林的账户、功能或服务；  
（3）下载使用他人违法破解的Anki笔记森林客户端程序，并以此达到违规使用Anki笔记森林的目的。  
（4）对Anki笔记森林软件进行反向汇编、反向编译或以其他方式尝试发现本软件的源代码；  
（5）对Anki笔记森林拥有知识产权的内容进行使用、出租、复制、修改、转载、汇编、发表等；  
（6）对Anki笔记森林软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据以及软件运行所必需的系统数据，使用插件或非Anki笔记森林授权的第三方工具/服务接入Anki笔记森林软件和相关系统进行复制、修改、增加、删除或创作衍生作品；  
（7）通过修改或伪造软件运行中的指令、数据，增加、删减、改变Anki笔记森林软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；通过非Anki笔记森林开发、授权的第三方软件、插件，登录或使用Anki笔记森林相关软件及服务，或制作、发布、传播上述工具；  
（8）自行或者授权他人、第三方软件、系统、设备对Anki笔记森林软件及其数据、服务进行干扰；  
（9）删除Anki笔记森林软件或服务中关于版权、商标权等知识产权声明的信息；  
（10）其他未经Anki笔记森林明示授权的行为。  
如果您有上述行为之一，我司有权不经事先通知中止或终止向您提供本服务，并且不会退还您已支付的所有费用。同时我司将视该行为引起后果的严重性，保留一切追究责任的权利，包括不限于通过法律途径追究一切责任的权利，您需要为此承担一切责任。  
3 违法违规责任  
您理解并同意，基于用户体验、服务运营安全、相关规则要求及网络健康发展等综合因素，我司有权针对以下情形进行相应的违约处理：如果我司发现或收到他人举报或投诉用户违反本协议约定的，我司有权不经事先通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于不予注册、通知限期改正、注销用户帐号、中断或终止用户对Anki笔记森林服务的使用等措施，并有权自行决定是否公告处理结果；如果用户违反相关法律法规及国家政策要求，我司有权对违法违规的任何用户采取适当的法律行动，并有权依据法律法规保存有关信息向相关主管部门报告等，用户应独自承担由此而产生的一切法律责任。  
4 用户必须授予Anki笔记森林的许可  
您理解并同意，在满足隐私政策的前提下您授予我们处理您的内容的特定有限许可，这样我司在运营服务中的技术操作将不会被视为违法行为。同时您还授权我司就任何第三方对该等内容或其衍生品的侵权以我司名义提起诉讼，并由我司获得全部赔偿。您保证有权或已取得充分且必要的授权对我司进行此授权。主要包括：在您使用Anki笔记森林服务过程中所上传的内容，您授予我司处理、维护、存储、备份和分发您的内容的许可。您还同意，我司有权自行判断选择拒绝接受、公布、存储、显示、发布或传输您的任何内容。  
前款权利和许可是免版税的、全球范围内可用的、不可撤销的。同时Anki笔记森林还拥有如下权利：为提供本服务之唯一目的，将此类内容提供给与我司拥有合同关系（与提供Anki笔记森林服务有关的合作）的其他人，并向其转移这些权利；我司遵守法律义务之需要，许可第三方访问或向第三方披露您的内容。  
最后，您理解并同意，我司在提供本服务所需的技术步骤中，出于符合和适应连接网络、设备、服务或媒体的技术要求的需要，可能对您的内容做出修改。  
   
### 六、服务费用  
您可以免费使用Anki笔记森林的免费功能或服务，我司拥有修改或终止该等免费功能或服务的权利。  
如果您有意愿付费解锁Anki笔记森林vip会员功能，这将表示您将同意支付其中的所有费用。在您提交使用付费服务的申请后，我司将提供经我司认可的第三方在线服务机构的支付方式，并要求您支付相关费用。成功支付后，表明您已经获得使用付费服务的权利并且已经达成此项交易。  
您理解并同意我司有权根据实际需要对本服务的服务内容、服务方式、是否收费、收费标准及收费方式等进行调整，在相关服务页面或以其他合理方式进行通知，并在通知后生效。您继续使用相关服务，视为您同意我司的前述调整。  
您理解并同意我司有权决定新增服务项目是否收费，您有权选择接受或拒绝该项目服务。  
需要特别指出的是，您有可能获得了免费限时试用Anki笔记森林vip会员功能权益或者免费使用部分Anki笔记森林云服务权益，我司有权不经事先通知变更、中断或终止部分或全部此等免费权益，或变更、删除、转移您在此等免费权益下存放在Anki笔记森林上的任何内容。您确认并接受我司行使前述变更、中断或终止服务的权利，我司不需就此对您或第三方承担任何责任。  
您在此确认并接受，无论本协议是否有相反约定，您一旦为组织开通任何付费服务，如您未支付费用或欠费，则我司有权暂停或停止向该组织提供全部服务。  
   
### 七、我司的权利及义务  
我司拥有本协议的所有合法权利。您同意我司拥有Anki笔记森林软件中包括所有知识产权在内的权利。同时，除了受本协议保护之外，也受一项或多项知识产权、商业秘密、其他法律法规和条约的保护。尤其是，您同意不修改Anki笔记森林软件的任何部分、不利用Anki笔记森林制作衍生作品、不反编译或以其他方式提取Anki笔记森林源代码，除非您根据开源许可证得到明确许可，或者得到我司的明确书面许可，或者有其他合法许可。  
我司拥有自行决定实施新元素之权利，无论是否另行通知，该新元素均作为本协议的组成部分，包括可能会影响先前经营模式或造成Anki笔记森林软件的更改。我司亦保留下列权利：设定为您提供的存储性质及大小、设定您收发信息的权限、设定您的内容及其他信息的性质或您访问或分发您的内容和其他数据的持续能力，并有权随时施加其他限制，无论是否另行通知。  
我司拥有基于合理理由可能在有限期间内或永久地妨碍或阻止您访问您的内容的权利。您同意，我司对任何此类行为或结果（包括但不限于删除任何内容或未能向您提供任何内容）造成的任何结果概不承担任何责任或义务。您同意，对于本服务的修改、中断或终止，我司对您或任何第三方概不承担任何责任。  
我司拥有修改Anki笔记森林所提供的功能和服务的权利。Anki笔记森林可能会不时自动在您的计算机和设备中推送软件更新，旨在改善、提高、修复或进一步做好服务。在任何情况下，您同意Anki笔记森林向您提供这些更新且您同意接收这些更新。  
   
### 八、我司的有限责任  
您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素导致Anki笔记森林服务发生中断。不可抗力是指不能预见、不能避免且不能克服的客观情况，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等；社会事件如战争、动乱、政府行为以及立法变化如法律政策修改、政府命令等。出现上述不可抗力情况时，我司将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失我司在法律允许的范围内免责。  
您理解并同意，我司对以下情形导致的服务中断或受阻不承担责任：  
（1）基础网络运营商或网络设备技术提供商的故障、计算机或互联网相关技术缺陷、互联网覆盖范围限制、受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏等网络服务特性而特有的情况；  
（2）用户或我司的电脑软件、系统、硬件、通信线路、公用事业及服务出现故障或中断的情况；  
（3）用户操作不当的情况；  
（4）用户安装Anki笔记森林的电脑或移动终端软硬件，由于Anki笔记森林未对该版本/型号的电脑或移动终端软硬件做适配性和兼容性开发导致的使用问题的情况；  
（5）用户通过非我司授权的方式使用Anki笔记森林服务的情况；  
（6）其他我司无法控制或合理预见的情况。  
您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，我司不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。我司依据本协议约定获得处理违法违规内容的权利，该权利不构成我司的义务或承诺。我司无法保证及时发现违法行为进而无法保证能够及时对违法行为进行相应处理。这些风险包括但不限于：  
（1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；  
（2）因使本服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；  
（3）其他因网络信息或用户行为引起的风险。  
您理解并同意，本服务可能接入或链接至第三方网站或服务，因该等第三方网站或服务由相关的第三方负责运营，我司无法对该等第三方网站或服务进行控制。您使用该等第三方网站或服务（包括您向该等第三方提供的任何信息），须受第三方的服务协议及信息保护声明约束，您需要仔细阅读其协议条款。如您发现这些第三方网站或服务存在风险时，建议您终止相关操作以保护您的合法权益，如您因使用或依赖上述第三方网站或服务所导致的损失或损害，我司不承担任何责任。  
在适用法律允许的最大范围内，我司不因您使用本服务引起的或与本服务有关的任何意外的、特殊的或间接的损害或请求（包括但不限于因人身伤害、因隐私泄漏、因未能履行包括诚信或合理谨慎在内的任何责任、因过失和因任何其他金钱上的损失或其他损失而造成的损害赔偿）承担任何责任。  
   
### 九、服务变更、中断或终止  
您可自行决定随时终止本服务，但除本协议另有约定外，您将无权要求我司退还截至该终止日期时已支付费用或者减免您应付费用。一旦您终止本服务，我司将有权删除您储存或发布在Anki笔记森林的内容。  
鉴于软件和网络服务的特殊性，我司有权随时变更、中断或终止部分或全部服务，或变更、删除、转移您储存或发布在Anki笔记森林的内容。我司保留采取前述行为而不需通知您的权利。您确认并接受我司行使前述变更、中断或终止服务的权利，我司不需就此对您或第三方承担任何责任。  
如果您付费购买了Anki笔记森林会员服务，如您对所购买的Anki笔记森林会员功能服务不满意，在您付费后的七天内可通过发邮件到1436238243@qq.com申请退款，超过七天则您无权申请任何退款。  
本服务需要定期或不定期地对提供本服务的平台或相关的设备进行检修或者维护，如因此类情况造成本服务在合理时间内的中断，我司无需为此承担任何责任，但我司应尽可能事先进行通告。  
如果因您在使用本服务过程中出现任何违反法律法规、本协议或其他规则的行为，我司有权不经事先通知终止您使用本服务，且不会退还您已支付的任何费用。  
如果您因我司违约而终止使用本服务，则您获得的唯一且排他性的救济以及我司对该等违约的全部责任不超过您根据本协议或其他书面协议就本服务向我司支付的费用。  
   
### 十、用户隐私制度  
尊重您个人隐私是我司的一项基本政策。请您仔细审阅 《Anki笔记森林隐私政策》（下称“该隐私政策”） ，一旦您使用或在我司更新该隐私政策后继续使用本服务，即视为您同意该隐私政策并同意我司按照该隐私政策处理您的相关个人信息。请您完整阅读该隐私政策，以帮助您更好地保护您的个人信息。  
   
### 十一、年龄限制  
您确认，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，确保有能力对您使用本服务的一切行为独立承担责任。若您不具备前述主体资格或您是未满十八周岁的未成年人，请在您的监护人同意本协议内容并同意您使用本服务之后使用本服务；我司在依据法律规定或本协议约定要求您承担责任时，有权向您的监护人追偿。  
   
### 十二、保障  
您同意保障和维护我司及其他用户的利益，如因您违反有关法律法规或本协议项下的任何条款而给我司或任何其他第三方造成损失，您同意承担由此造成的损害并负责支付相关费用，包括不限于律师费用、损害补偿费用、政府机关处罚费用和其它侵权赔偿费用等。  
   
### 十三、通知  
所有发给您的通知都可通过电子邮件、短信通知、站内通知、微信公众号通知、网站或客户端程序中公告的方式进行传送。我司将通过上述方法之一将消息传递给您，告知您本协议的修改、本服务的变更或其它重要事项。同时，我司保留在Anki笔记森林中投放商业性广告的权利。  
   
### 十四、法律管辖  
本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。  
如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决。协商不成时，任何一方均可向北京欧拉星空智能科技有限公司住所地有管辖权的人民法院提起诉讼。  
   
### 十五、其他规定  
本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的条款，未赋予本协议各方其他权利。  
本协议中的任何条款，无论因何种原因导致的部分无效或不具有执行力，本协议的其余条款仍应有效并且对各方具有约束力。  
北京欧拉星空智能科技有限公司对本协议的相关条款拥有最终解释权。  
    `}
            </Markdown>
        </div>
    )
}