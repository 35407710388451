import { PrimaryColor } from "./Themes"
import { Link } from "react-router-dom"
import { IphoneContainer } from "./common/IphoneContainer"
import { IpadContainer } from "./common/IpadContainer"
import { CSSProperties, useState } from "react"
import { ExportAppPreview } from "./common/ExportAppPreview"
import { TextField } from "@mui/material"

export const PreviewView = () => {
    const [iphoneW, setIphoneW] = useState(1290);
    const [iphoneH, setIphoneH] = useState(2796);
    const [ipadW, setIpadW] = useState(2048);
    const [ipadH, setIpadH] = useState(2732);
    const style1 = (bt: "bottom" | "top"): CSSProperties => ({
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: "#fff",
        alignItems: "center",
        justifyContent: "center",
    })
    const style2: CSSProperties = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 20,
        flex: 1
    }
    const style3: CSSProperties = {
        margin: 10
    }
    return (
        <div style={{
        }}>
            <div style={style1("top")}>
                <div style={style2}>
                    <div style={style3}>
                        <TextField 
                            label="width" 
                            variant="outlined"
                            value={iphoneW}
                            onChange={(e) => {
                                const v = parseInt(e.target.value);
                                if (isNaN(v)) return;
                                setIphoneW(v);
                            }}
                        />
                    </div>
                    <div style={style3}>
                        <TextField 
                            label="height" 
                            variant="outlined"
                            value={iphoneH}
                            onChange={(e) => {
                                const v = parseInt(e.target.value);
                                if (isNaN(v)) return;
                                setIphoneH(v);
                            }}
                        />
                    </div>
                </div>
                <div style={style2}>
                    <div style={style3}>
                        <TextField 
                            label="width" 
                            variant="outlined"
                            value={ipadW}
                            onChange={(e) => {
                                const v = parseInt(e.target.value);
                                if (isNaN(v)) return;
                                setIpadW(v);
                            }}
                        />
                    </div>
                    <div style={style3}>
                        <TextField 
                            label="height" 
                            variant="outlined" 
                            value={ipadH}
                            onChange={(e) => {
                                const v = parseInt(e.target.value);
                                if (isNaN(v)) return;
                                setIpadH(v);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div style={style1("top")}>
                <div style={style2}>
                    <ExportAppPreview
                        ratio={iphoneH/iphoneW}
                        device="iphone"
                        background={`linear-gradient(to top, ${PrimaryColor.color4}, ${PrimaryColor.color1})`}
                        title="可视化笔记森林"
                        desc={[
                            "基于深度学习的FSRS定期复习算法", 
                            "树苗随复习而长大",
                            "让复习不再枯燥",
                        ]}
                        exportHeight={iphoneH}
                        exportWidth={iphoneW}
                    >
                        <img
                            src={require("../assets/iphone_screenshot/1.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </ExportAppPreview>
                </div>
                <div style={style2}>
                    <ExportAppPreview
                        ratio={ipadH/ipadW}
                        device="ipad"
                        background={`linear-gradient(to top, ${PrimaryColor.color4}, ${PrimaryColor.color1})`}
                        title="可视化笔记森林"
                        desc={[
                            "基于深度学习的FSRS定期复习算法", 
                            "树苗随复习而长大",
                            "让复习不再枯燥",
                        ]}
                        exportHeight={ipadH}
                        exportWidth={ipadW}
                    >
                        <img
                            src={require("../assets/ipad_screenshots/1.png")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </ExportAppPreview>
               </div>
            </div>
            <div style={style1("bottom")}>
                <div style={style2}>
                    <ExportAppPreview
                        ratio={iphoneH/iphoneW}
                        device="iphone"
                        background={`linear-gradient(to top, ${PrimaryColor.color4}, ${PrimaryColor.color1})`}
                        title="学习资料统一管理"
                        desc={[
                            "可以导入视频合辑与PDF", 
                            "灵活管理学习资料",
                            "自建笔记云同步服务,多端设备数据互通"
                        ]}
                        exportHeight={iphoneH}
                        exportWidth={iphoneW}
                    >
                        <img
                            src={require("../assets/iphone_screenshot/2.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </ExportAppPreview>
                </div>
                <div style={style2}>
                    <ExportAppPreview
                        ratio={ipadH/ipadW}
                        device="ipad"
                        background={`linear-gradient(to top, ${PrimaryColor.color4}, ${PrimaryColor.color1})`}
                        title="学习资料统一管理"
                        desc={[
                            "可以导入视频合辑与PDF", 
                            "灵活管理学习资料",
                            "自建笔记云同步服务,多端设备互通"
                        ]}
                        exportHeight={ipadH}
                        exportWidth={ipadW}
                    >
                        <img
                            src={require("../assets/ipad_screenshots/4.png")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </ExportAppPreview>
                </div>
            </div>
            <div style={style1("top")}>
                <div style={style2}>
                    <ExportAppPreview
                        ratio={iphoneH/iphoneW}
                        device="iphone"
                        background={`linear-gradient(to top, ${PrimaryColor.color4}, ${PrimaryColor.color1})`}
                        title="内嵌视频与PDF浏览器"
                        desc={[
                            "支持视频合辑", 
                            "单手操作适用于地铁，排队等场景",
                            "在学习的过程中随时截图"
                        ]}
                        exportHeight={iphoneH}
                        exportWidth={iphoneW}
                    >
                        <img
                            src={require("../assets/iphone_screenshot/6.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </ExportAppPreview>
                </div>
                <div style={style2}>
                    <ExportAppPreview
                        ratio={ipadH/ipadW}
                        device="ipad"
                        background={`linear-gradient(to top, ${PrimaryColor.color4}, ${PrimaryColor.color1})`}
                        title="内嵌视频与PDF浏览器"
                        desc={[
                            "支持视频合辑", 
                            "单手操作适用于地铁，排队等场景",
                            "在学习的过程中随时截图"
                        ]}
                        exportHeight={ipadH}
                        exportWidth={ipadW}
                    >
                        <img
                            src={require("../assets/ipad_screenshots/5.png")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </ExportAppPreview>
                </div>
            </div>
            <div style={style1("bottom")}>
                <div style={style2}>
                    <ExportAppPreview
                        ratio={iphoneH/iphoneW}
                        device="iphone"
                        background={`linear-gradient(to top, ${PrimaryColor.color4}, ${PrimaryColor.color1})`}
                        title="FSRS算法定期复习"
                        desc={[
                            "在复习时直接跳转到PDF或视频相应位置", 
                            "正反面卡片关键知识点记忆",
                            "集中突击全量复习模式应对考试"
                        ]}
                        exportHeight={iphoneH}
                        exportWidth={iphoneW}
                    >
                        <img
                            src={require("../assets/iphone_screenshot/5.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </ExportAppPreview>
                </div>
                <div style={style2}>
                    <ExportAppPreview
                        ratio={ipadH/ipadW}
                        device="ipad"
                        background={`linear-gradient(to top, ${PrimaryColor.color4}, ${PrimaryColor.color1})`}
                        title="FSRS算法定期复习"
                        desc={[
                            "在复习时直接跳转到PDF或视频相应位置", 
                            "正反面卡片关键知识点记忆",
                            "集中突击全量复习模式应对考试"
                        ]}
                        exportHeight={ipadH}
                        exportWidth={ipadW}
                    >
                        <img
                            src={require("../assets/ipad_screenshots/3.png")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </ExportAppPreview>
                </div>
            </div>
            <div style={style1("top")}>
                <div style={style2}>
                    <ExportAppPreview
                        ratio={iphoneH/iphoneW}
                        device="iphone"
                        background={`linear-gradient(to top, ${PrimaryColor.color4}, ${PrimaryColor.color1})`}
                        title="应用内分屏双PDF模式"
                        desc={[
                            "答案和题目在不同PDF或者相距较远？", 
                            "双PDF模式题目录入卡片正面",
                            "答案录入卡片背面"
                        ]}
                        exportHeight={iphoneH}
                        exportWidth={iphoneW}
                    >
                        <img
                            src={require("../assets/iphone_screenshot/4.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </ExportAppPreview>
                </div>
                <div style={style2}>
                    <ExportAppPreview
                        ratio={ipadH/ipadW}
                        device="ipad"
                        background={`linear-gradient(to top, ${PrimaryColor.color4}, ${PrimaryColor.color1})`}
                        title="应用内分屏双PDF模式"
                        desc={[
                            "答案和题目在不同PDF或者相距较远？", 
                            "双PDF模式题目录入卡片正面",
                            "答案录入卡片背面"
                        ]}
                        exportHeight={ipadH}
                        exportWidth={ipadW}
                    >
                        <img
                            src={require("../assets/ipad_screenshots/2.png")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </ExportAppPreview>
                </div>
            </div>
            <div style={style1("top")}>
                <div style={style2}>
                    <ExportAppPreview
                        ratio={iphoneH/iphoneW}
                        device="iphone"
                        background={`linear-gradient(to top, ${PrimaryColor.color4}, ${PrimaryColor.color1})`}
                        title="截图到卡片只需20秒"
                        desc={[
                            "简洁精要的图片编辑功能", 
                            "从截图到制作一张卡片只需20秒",
                        ]}
                        exportHeight={iphoneH}
                        exportWidth={iphoneW}
                    >
                        <img
                            src={require("../assets/iphone_screenshot/7.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </ExportAppPreview>
                </div>
                <div style={style2}>
                    <ExportAppPreview
                        ratio={ipadH/ipadW}
                        device="ipad"
                        background={`linear-gradient(to top, ${PrimaryColor.color4}, ${PrimaryColor.color1})`}
                        title="截图到卡片只需20秒"
                        desc={[
                            "简洁精要的图片编辑功能", 
                            "从截图到制作一张卡片只需20秒",
                        ]}
                        exportHeight={ipadH}
                        exportWidth={ipadW}
                    >
                        <img
                            src={require("../assets/ipad_screenshots/6.png")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </ExportAppPreview>
                </div>
            </div>
 
        </div>
    )
}