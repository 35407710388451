import { IpadContainer } from "./common/IpadContainer"
import { IphoneContainer } from "./common/IphoneContainer"
import { PrimaryColor, SecondaryColor } from "./Themes"

export const HomeView = () => {
    return (
        <div>
            <div style={{
                padding: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: `linear-gradient(to bottom, ${PrimaryColor.color2}, #ffffff)`
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 20,
                    justifyContent: "center",
                    alignItems: "center"
                }}>

                <text style={{
                    fontSize: 50,
                    fontWeight: "bolder",
                }}>
                    {"让学习更高效"}
                </text>
                <text style={{
                    fontSize: 15,
                    fontWeight: "bolder",
                }}>
                    {"自学/考研/考公/考试"}
                </text>
                <text style={{
                    fontSize: 10,
                    fontWeight: "bolder",
                    marginTop: 30,
                }}>
                    {"成功就是不断重复简单的事情。"}
                </text>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 20,
                    justifyContent: "center",
                    alignItems: "center"
                }}>

                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"专注于笔记的制作与复习"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"导入PDF与视频合辑"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"从视频和PDF截图并遮挡知识点生成正反卡片"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"复习卡片时可以跳转到视频与PDF的相应位置"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"基于深度学习的FSRS定期复习算法让复习更高效"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"用树苗的生长可视化对知识点的熟练度"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"支持电脑，安卓，iOS，自建多端笔记同步云服务"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"随时随地单手持握手机就能进行学习与卡片制作"}
                </text>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: "#fff",
                alignItems: "center",
                justifyContent: "center",
                background: `linear-gradient(to top, ${PrimaryColor.color2}, #ffffff)`
            }}>
                <div style={{
                    flex: 1
                }} />
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20
                }}>
                    <IphoneContainer height={600}>
                        <img
                            src={require("../assets/iphone_screenshot/1.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </IphoneContainer>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20
                }}>
                    <IpadContainer height={600}>
                        <img
                            src={require("../assets/ipad_screenshots/1.png")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </IpadContainer>
                </div>
                <div style={{ flex: 1 }} />
            </div>
            <div style={{
                display: "flex",
                background: `linear-gradient(to bottom, ${PrimaryColor.color2}, #ffffff)`,
                paddingTop: 50
            }}>
                <div style={{ flex: 1 }} />
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20,
                    flexDirection: 'column'
                }}>
                    {
                        [
                            "学习资料与笔记卡片管理混乱？",
                            "导入PDF与视频合辑",
                            "自由管理学习资料与笔记本",
                            "多端同步笔记",
                        ].map((v, i) => {
                            return (
                                <div key={i}
                                    style={{
                                        backgroundColor: PrimaryColor.color4,
                                        padding: 10,
                                        border: "none",
                                        borderRadius: 10,
                                        margin: 10
                                    }}
                                >
                                    <text style={{
                                        fontSize: 20,
                                        fontWeight: "bold",
                                        color: "#fff"
                                    }}>
                                        {v}
                                    </text>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20
                }}>
                    <IphoneContainer height={600}>
                        <img
                            src={require("../assets/iphone_screenshot/2.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </IphoneContainer>
                </div>
                <div style={{ flex: 1 }} />
            </div>
            <div style={{
                display: "flex",
                background: `linear-gradient(to top, ${PrimaryColor.color2}, #ffffff)`,
                paddingTop: 50
            }}>
                <div style={{ flex: 1 }} />

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20
                }}>
                    <IphoneContainer height={600}>
                        <img
                            src={require("../assets/iphone_screenshot/6.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </IphoneContainer>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20
                }}>
                    <IphoneContainer height={600}>
                        <img
                            src={require("../assets/iphone_screenshot/5.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </IphoneContainer>
                </div>
 
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20,
                    flexDirection: 'column'
                }}>

                    {
                        [
                            "卡片制作麻烦？",
                            "想查看看片来源的位置？",
                            "浏览视频或者PDF时截图",
                            "遮挡关键知识点",
                            "10秒制作一张卡片",
                            "复习卡片时可以直接跳转到视频或者PDF",
                        ].map((v, i) => {
                            return (
                                <div key={i}
                                    style={{
                                        backgroundColor: PrimaryColor.color4,
                                        padding: 10,
                                        border: "none",
                                        borderRadius: 10,
                                        margin: 10
                                    }}
                                >
                                    <text style={{
                                        fontSize: 20,
                                        fontWeight: "bold",
                                        color: "#fff"
                                    }}>
                                        {v}
                                    </text>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ flex: 1 }} />
            </div>
            <div style={{
                display: "flex",
                background: `linear-gradient(to bottom, ${PrimaryColor.color2}, #ffffff)`,
                paddingTop: 50
            }}>
                <div style={{ flex: 1 }} />
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20,
                    flexDirection: 'column'
                }}>
                    {
                        [
                            "复习无聊枯燥？",
                            "闪卡容易堆积？",
                            "根据复习记录计算你对笔记的记忆熟练度",
                            "用树苗生长可视化复习过程",
                            "记得越牢树苗越茁壮",
                            "基于深度学习的FSRS定期复习算法",
                            "让你的复习更自由更高效"
                        ].map((v, i) => {
                            return (
                                <div key={i}
                                    style={{
                                        backgroundColor: PrimaryColor.color4,
                                        padding: 10,
                                        border: "none",
                                        borderRadius: 10,
                                        margin: 10
                                    }}
                                >
                                    <text style={{
                                        fontSize: 20,
                                        fontWeight: "bold",
                                        color: "#fff"
                                    }}>
                                        {v}
                                    </text>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20
                }}>
                    <IphoneContainer height={600}>
                        <img
                            src={require("../assets/iphone_screenshot/1.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </IphoneContainer>
                </div>
                <div style={{ flex: 1 }} />
            </div>
            <div style={{
                display: "flex",
                background: `linear-gradient(to top, ${PrimaryColor.color2}, #ffffff)`,
                paddingTop: 50
            }}>
                <div style={{ flex: 1 }} />
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20
                }}>
                    <IphoneContainer height={600}>
                        <img
                            src={require("../assets/iphone_screenshot/4.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </IphoneContainer>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20,
                    flexDirection: 'column'
                }}>
                    {
                        [
                            "题目与答案在不同PDF或相隔页数较远?",
                            "来回翻看？",
                            "应用内分屏双PDF模式",
                            "题目录入卡片正面",
                            "答案录入卡片背面",
                        ].map((v, i) => {
                            return (
                                <div key={i}
                                    style={{
                                        backgroundColor: PrimaryColor.color4,
                                        padding: 10,
                                        border: "none",
                                        borderRadius: 10,
                                        margin: 10
                                    }}
                                >
                                    <text style={{
                                        fontSize: 20,
                                        fontWeight: "bold",
                                        color: "#fff"
                                    }}>
                                        {v}
                                    </text>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ flex: 1 }} />
            </div>
 

        </div>
    )
}
