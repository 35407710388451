import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Helmet } from 'react-helmet';
import './index.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Helmet>
        <title>Anki笔记森林</title>
    </Helmet>
    <App />
  </React.StrictMode>
);
